<template>
  <main v-if="!isViewPayslip" class="mx-auto min-h-screen px-2 sm:px-4 py-4">
    <div
      class="flex flex-row-reverse items-center justify-between sm:flex-col sm:items-start mb-6 sm:mb-0"
    >
      <h4
        class="md:mt-3 md:text-sm md:text-left text-xs font-medium text-dark-600 sm:text-headerText capitalize sm:uppercase"
      >
        Payroll / Payslips / {{ months[payload.month] }} {{ payload.year }}
      </h4>
      <div class="no-printable flex justify-start sm:mt-4">
        <easiButton
          @click="goBack"
          variant="text"
          color="dark-800"
          size="small"
          class="-ml-2"
        >
          <i class="text-sm fas fa-angle-left mr-3 cursor-pointer"></i>
          <span class="text-sm">Back</span>
        </easiButton>
      </div>
    </div>

    <div class="w-full md:px-10">
      <div class="my-6 md:my-10 w-full mx-auto">
        <h2 class="text-center font-bold text-2xl md:text-3xl mb-8">
          {{ months[payload.month] }} {{ payload.year }} Payslips
        </h2>
        <div
          class="flex mb-2 flex-col md:flex-row md:items-center mt-4 w-full md:w-full justify-between mx-auto grow-0 gap-4"
        >
          <div class="flex items-center w-full">
            <!-- /////// Search Component ///// -->
            <div class="w-10/12 md:w-full">
              <easiSearch
                placeholder="Search employee by name"
                useNewclass
                v-model="searchText"
                @search="searchFN"
              />
            </div>
            <!-- <div class="w-full md:w-6/12">
              <PayrollSearch
                placeholder="Search employee by name"
                v-model="searchText"
                @search="searchFN"
              />
            </div> -->

            <!-- /////// Filter Component Start /////// -->
            <div class="">
              <PayrollFilter
                ref="payslipDetailRef"
                filterType="payslip"
                @update="filterFN"
                class="ml-4"
              />
            </div>
            <!-- /////// Filter Component Ends /////// -->
          </div>

          <div class="flex items-center gap-3">
            <easiButton
              @click="sendPayslip"
              :loading="payslipLoading"
              class="py-4"
            >
              <span class=""> Send Payslip </span>
            </easiButton>

            <easiButton
              @click="downloadPayslip"
              :loading="payslipDownloadLoading"
              class="py-4"
            >
              <span class=""> Download </span>
            </easiButton>
          </div>
        </div>
        <easiActiveFilter @close="removeFilter" :filters="activeFilters" />
      </div>
    </div>

    <div class="w-full">
      <payslip-details-table
        @selected="updateSelectedEmployees"
        :searchText="searchText"
        :is-search="isSearch"
      />
    </div>
    <easiSuccess v-if="payslipSuccess" @close="payslipSuccess = false">
      <template v-slot:message>
        <span>Payslip(s) sent successfully</span>
      </template>
    </easiSuccess>

    <easiRoundLoader v-if="loading" />
  </main>
  <main v-else>
    <view-payslip />
  </main>
</template>

<script setup>
import PayslipDetailsTable from "@/components/Payroll/Payslip/PayslipDetailsTable.vue";
import ViewPayslip from "@/components/Payroll/Payslip/ViewPayslip.vue";
import PayrollFilter from "@/components/Filters/PayrollFilter.vue";
import PayrollSearch from "@/components/Search/PayrollSearch.vue";

import { computed, ref, onMounted, watch, reactive } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { storeToRefs } from "pinia";

const store = useDataStore();
const { query, mutate } = store;

const { formatAmount, downloadBase64 } = helperFunctions;

const payrollForMonth = computed(() => store.getPayrollHistoryForMonth);

const route = useRoute();
const router = useRouter();
const toast = useToast();

const payslipSuccess = ref(false);

const isViewPayslip = computed(() => {
  const queryParams = route.query;
  return queryParams && queryParams.id;
});

const loading = ref(false);
const payslipLoading = ref(false);
const searchText = ref("");
let isSearch = ref(false);
const filterOptions = ref({
  sort: { firstName: "ASC" },
  filter: [],
});
const activeFilters = ref([]);
const payslipDetailRef = ref(null);

let selectedEmployees = ref([]);
const currentPage = ref(0);
const pageSize = ref(2);
let visibleData = ref([]);

const payload = reactive({
  month: null,
  year: null,
  timeline: null,
  biweekly: null,
  sort: {},
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
  },
  filter: [],
});

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

watch(searchText, async (val) => {
  if (val.trim() === "") {
    payload.paging.search = "";
    await queryViewPayrollForMonthV3();
    isSearch.value = false;
  }
});

onMounted(() => {
  const payrollDate = route.params.date.split(",");
  payload.month = parseInt(payrollDate[0]);
  payload.year = parseInt(payrollDate[1]);
  payload.timeline = payrollDate[2];
  payload.biweekly = payrollDate[3];

  queryViewPayrollForMonthV3();
});

const goBack = () => {
  console.log(route.params);
  if (route.params) {
    router.replace({ params: null });
  }
  router.push({ name: "Payslip" });
};

const updatePage = (pageNumber) => {
  currentPage.value = pageNumber;
  // updateVisibleData();
};

const searchFN = async (suggested) => {
  // console.log(suggested, searchText.value);
  searchText.value = suggested ? suggested : searchText.value;
  isSearch.value = true;
  payload.paging.search = searchText.value;
  await queryViewPayrollForMonthV3();
};

const filterFN = async ({ filter, active }) => {
  isSearch.value = true;
  filterOptions.value = filter;

  payload.sort = filterOptions.value.sort;
  payload.filter = filterOptions.value.filter;

  console.log("Payload");
  console.log(payload);
  await queryViewPayrollForMonthV3();

  activeFilters.value = active;
};

const removeFilter = (arg) => {
  console.log(payslipDetailRef.value);
  if (payslipDetailRef.value && payslipDetailRef.value.removeFilter) {
    payslipDetailRef.value.removeFilter(arg);
  }
};

function updateSelectedEmployees(arg) {
  selectedEmployees.value = [];
  selectedEmployees.value = arg;
}

const payslipDownloadLoading = ref(false);
const downloadPayslip = async () => {
  const obj = {
    employeeIds: selectedEmployees.value,
    month: payload && payload.month,
    year: payload && payload.year,
    timeline: payload && payload.timeline ? payload.timeline : "MONTHLY",
    biweekly: payload && payload.biweekly,
    download: true,
  };

  if (selectedEmployees.value.length) {
    try {
      payslipDownloadLoading.value = true;

      const res = await mutate({
        endpoint: "SendPayslipZip",
        data: {
          input: obj,
        },
        service: "PAYROLL",
      });

      if (res.success) {
        const fileObj = res.data.base64s[0];
        downloadBase64(
          fileObj.Base64Content,
          fileObj.Filename,
          undefined,
          true
        );
        // payslipSuccess.value = true;
        queryViewPayrollForMonthV3();

        // setTimeout(() => {
        //   payslipSuccess.value = false;
        // }, 3000);
        // log(res);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      payslipDownloadLoading.value = false;
    }
  } else {
    toast.error("Please select an employee");
  }
};

const sendPayslip = async () => {
  const obj = {
    employeeIds: selectedEmployees.value,
    month: payload && payload.month,
    year: payload && payload.year,
    timeline: payload && payload.timeline ? payload.timeline : "MONTHLY",
    biweekly: payload && payload.biweekly,
  };

  if (selectedEmployees.value.length) {
    try {
      payslipLoading.value = true;

      const res = await mutate({
        endpoint: "SendPaySlipEmails",
        data: {
          input: obj,
        },
        service: "PAYROLL",
      });

      payslipLoading.value = false;
      if (res.success) {
        payslipSuccess.value = true;
        queryViewPayrollForMonthV3();

        setTimeout(() => {
          payslipSuccess.value = false;
        }, 3000);
        log(res);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      payslipLoading.value = false;
      console.log(err);
    } finally {
      loading.value = false;
    }
  } else {
    toast.error("Please select an employee");
  }
};

const queryViewPayrollForMonthV3 = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ViewPayrollForMonthV3",
      payload: {
        input: payload,
      },
      service: "PAYROLL",
      storeKey: "payrollForMonth",
    });

    console.log("payrollForMonth");
    log(payrollForMonth.value);
    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}

const display = (arg) => {
  return arg ? arg : "N/A";
};
</script>

<style></style>
